import React from 'react'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import MetaTags from 'components/MetaTags'
import Section, { SectionBlock } from 'components/Section'
import Wrapper from 'components/Wrapper'
import Header from 'components/Header'
import Heading from 'components/Heading'
import List from 'components/List'
import FeatureBox from 'components/FeatureBox'
import PhotoGallery from 'components/PhotoGallery'
import GridRow from 'components/grid/GridRow'
import GridItem from 'components/grid/GridItem'
import Input from '../components/Input'

const Concept = ({ data }) => {
  const cmsData = data.prismic.allConcept_pages.edges[0].node

  const {
    headerImage: {
      childImageSharp: { fluid },
    },
  } = data
  return (
    <Layout>
      <MetaTags title='Concept | Hotel Griffintown' />
      <Header
        eyebrow='Concept'
        heading={cmsData.heading[0].text}
        fluid={fluid}
      />
      <Section>
        <Wrapper size='narrow'>
          <SectionBlock>
            <GridRow>
              <GridItem xs='1-1' lg='3-4'>
                <Heading
                  size={3}
                  variant='sans'
                  css={`
                    margin-bottom: 0.5em;
                  `}
                >
                  {cmsData.subheading[0].text}
                </Heading>
                {cmsData.content.map(p => (
                  <p>{p.text}</p>
                ))}
              </GridItem>
            </GridRow>
          </SectionBlock>
          <SectionBlock>
            <GridRow>
              {cmsData.amenity_group.map(group => (
                <GridItem xs='1-1' md='1-2' equalHeight>
                  <FeatureBox heading={group.title[0].text} icon='fork'>
                    <List items={group.list.map(e => e.text)} />
                  </FeatureBox>
                </GridItem>
              ))}
            </GridRow>
          </SectionBlock>
        </Wrapper>
      </Section>
      <Section flushTop>
        <Wrapper>
          <PhotoGallery
            photos={[
              { src: require('../assets/images/concept/bedroom.jpg') },
              { src: require('../assets/images/concept/kitchen.jpg') },
              { src: require('../assets/images/concept/bathroom.jpg') },
              { src: require('../assets/images/concept/washer.jpg') },
              { src: require('../assets/images/concept/living.jpg') },
            ]}
          />
        </Wrapper>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query AmenitiesPage($language: String!) {
    headerImage: file(name: { eq: "img-hero-concept" }) {
      childImageSharp {
        fluid(maxWidth: 2560, quality: 100, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prismic {
      allConcept_pages(lang: $language) {
        edges {
          node {
            content
            heading
            subheading
            amenity_group {
              list
              title
            }
          }
        }
      }
    }
  }
`

export default Concept
