import React from 'react'
import styled from 'styled-components'
import { node, string } from 'prop-types'
import { stripUnit } from 'polished'

import { media } from 'styles/media'
import * as spacing from 'styles/spacing'
import { boxShadow } from 'styles/helpers'
import { white } from 'styles/colors'

import Heading from 'components/Heading'

const Container = styled.div`
  position: relative;
  padding: ${spacing.large};
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${white};
  ${boxShadow};

  ${media.medium`
    padding: ${spacing.xLarge};
  `};

  ${media.large`
    min-height: 267px;
  `};

  ${media.xLarge`
    min-height: 293px;
    padding: ${spacing.xxLarge};
  `};
`

const Header = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: ${stripUnit(spacing.small) * 1.5 + 'px'};

  ${media.medium`
    margin-bottom: ${spacing.medium};
  `};
`

const Content = styled.div`
  position: static;

  ${media.small`
    column-count: 2;
  `};

  ${media.medium`
    column-count: 1;
  `};

  ${media.large`
    column-count: 2;
  `};
`

const FeatureBox = ({ heading, children }) => (
  <Container>
    <Header>
      <Heading size={4} variant='sans'>
        {heading}
      </Heading>
    </Header>
    <Content>{children}</Content>
  </Container>
)

FeatureBox.propTypes = {
  heading: string.isRequired,
  children: node.isRequired,
}

export default FeatureBox
